<template>
  <div>
    <header-admin-pages
      title="Форумы"
      :counter="getForums.length"
      :button="headerButton"/>

    <table>
      <tr>
        <td>Название форума</td>
        <td>Год</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="forum in reversed(getForums)" :key="`forum${forum.id}`">
        <td @click="clickItem(forum.id)">{{ forum.title }}</td>
        <td @click="clickItem(forum.id)">{{ forum.year }}</td>
        <td class="drop-icon">
          <drop-list @clickItem="clickItem"
                     :id-item="forum.id"
                     :edit-link="`/admin/forum/${forum.id}/edit`"/>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'

export default {
  name: 'forum',

  components: {
    DropList,
    HeaderAdminPages
  },

  data () {
    return {
      headerButton: {
        title: 'Добавить форум',
        link: '/admin/forum/create'
      }
    }
  },

  mounted () {
    this.$store.dispatch('getMainForumsList')
  },

  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить форум?')
        if (consent) {
          this.$store.dispatch('deleteMainForum', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$router.push({ path: `/admin/forum/${value}/edit` })
      }
    },
    reversed (item) {
      return item !== null ? [...item].reverse() : ''
    }
  },

  computed: {
    getForums () {
      return this.$store.getters.GET_MAIN_FORUMS
    }
  }

}
</script>

<style scoped lang="scss">
  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        width: 47%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 0 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
